<template>
  <div class="big_box">
    <div class="top_title">
      <div>
        <el-radio v-model="radio" label="1">航空铁路</el-radio>
        <el-radio v-model="radio" label="2">公路水路</el-radio>
      </div>
      <div class="top_input clearfix">
        <h6>发票代码</h6>
        <el-input style="width:50%;"  size="small"></el-input>
      </div>
      <div class="bottom_time clearfix">
        <div class="top_input2 clearfix fl">
          <h6>发票号</h6>
          <el-input style="width:50%;"  size="small"></el-input>
        </div>
        <div class="top_input2 clearfix fr">
          <h6>开票日期</h6>
          <el-date-picker type="date" placeholder="选择日期时间" size="small"></el-date-picker>
        </div>
      </div>
    </div>

    <div class="content">
      <!-- 购方 -->
      <el-row :span="24" class="row_box2">
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content height_font">
            <p>购方单位</p>
          </div>
        </el-col>
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content">
            <ul class="list_info weight">
              <li>名称<span class="star">*</span></li>
              <li>纳税人识别号</li>
              <li>地址、电话</li>
              <li>开户行及账号</li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="10" :sm="10" :lg="10">
          <div class="grid-content">
            <ul class="list_info border_none">
              <li></li>
              <li><input type="text" ></li>
              <li><input type="text" ></li>
              <li><input type="text" ></li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content">
            <ul class="list_info">
              <li>
                <span >抵扣状态</span>
              </li>
              <li>
                <span >
                  抵扣方式</span>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="7" :sm="7" :lg="7">
          <div class="grid-content">
            <ul class="list_info">
              <li>
                <div>
                  <el-radio disabled v-model="radioStatus" :label="1">本期认证</el-radio>
                  <el-radio disabled v-model="radioStatus" :label="2">暂不认证</el-radio>
                </div>
              </li>
              <li>
                <div>
                  <el-radio disabled v-model="radioWay" :label="1">一般项目</el-radio>
                  <el-radio disabled v-model="radioWay" :label="2">即征即退</el-radio>
                </div>
              </li>
              <li>
              </li>
              <li>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
      <!-- 购方 end -->

      <!-- 中间栏 -->
      <div class="item_box item_list">
        <el-row :span="24">
          <el-col :xs="4" :sm="4" :lg="4">
            <div class="grid-content bg-purple">业务类型
              <span class='star'>*</span>
            </div>
          </el-col>
          <el-col :xs="4" :sm="4" :lg="4">
            <div class="grid-content bg-purple">货物或应税劳务名称
              <span class='star'>*</span>
            </div>
          </el-col>
          <el-col :xs="3" :sm="3" :lg="3">
            <div class="grid-content bg-purple">金额
              <span class='star'>*</span>
            </div>
          </el-col>
          <el-col :xs="2" :sm="2" :lg="2">
            <div class="grid-content bg-purple">税率
              <span class='star'>*</span>
            </div>
          </el-col>
          <el-col :xs="4" :sm="4" :lg="4">
            <div class="grid-content bg-purple">税额
              <span class='star'>*</span>
            </div>
          </el-col>
          <el-col :xs="4" :sm="4" :lg="4">
            <div class="grid-content bg-purple">价税合计
                <span class='star'>*</span>
            </div>
          </el-col>
          <el-col :xs="3" :sm="3" :lg="3">
            <div class="grid-content bg-purple">报销人
                <span class='star'>*</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 中间栏 end -->
      <!-- items -->
      <div class="item_box border_none">
        <el-row :span="24">
          <!-- 业务类型 (科目)-->
          <el-col :xs="4" :sm="4" :lg="4">
            <div class="grid-content"></div>
          </el-col>
          <!-- 货物或应税劳务名称 -->
          <el-col :xs="4" :sm="4" :lg="4">
            <div class="grid-content"></div>
          </el-col>
          <!-- 金额 -->
          <el-col :xs="3" :sm="3" :lg="3" style="height:100%;;text-align:center;">
            <input type="text" >
          </el-col>
          <!-- 税率 -->
          <el-col :xs="2" :sm="2" :lg="2" style="height:100%;;text-align:center;">
            <input type="text" >
          </el-col>
          <!-- 税额 -->
          <el-col :xs="4" :sm="4" :lg="4" style="height:100%;;text-align:center;">
            <input type="text" >
          </el-col>
          <!-- 价税合计 -->
          <el-col :xs="4" :sm="4" :lg="4" style="height:100%;;text-align:center;">
            <input type="text" >
          </el-col>
          <!-- 报销人 -->
          <el-col :xs="3" :sm="3" :lg="3" style="height:100%;;text-align:center;">
            <input type="text" >
          </el-col>
        </el-row>
      </div>
      <!-- 共计 -->
      <div class="total">
        <el-row :span="24">
          <el-col :xs="5" :sm="5" :lg="5">
            <div class="grid-content bg-purple">价税合计（大写）</div>
          </el-col>
          <el-col :xs="7" :sm="7" :lg="7">
            sumTotal
          </el-col>
          <el-col :xs="5" :sm="5" :lg="5">
            <div class="grid-content bg-purple">价税合计（小写）</div>
          </el-col>
          <el-col :xs="7" :sm="7" :lg="7" style="height:100%;;text-align:center;">
            <input type="text" >
          </el-col>
        </el-row>
      </div>
      <!-- 共计-end -->
      <!-- 销方 -->
      <el-row :span="24" class="row_box2">
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content height_font">
            <p>销方单位</p>
          </div>
        </el-col>
        <el-col :xs="3" :sm="3" :lg="3">
          <div class="grid-content">
            <ul class="list_info weight">
              <li>名称<span class="star">*</span></li>
              <li>纳税人识别号</li>
              <li>地址、电话</li>
              <li>开户行及账号</li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="7" :sm="7" :lg="7">
          <div class="grid-content">
            <ul class="list_info border_none">
              <li></li>
              <li><input type="text" ></li>
              <li><input type="text" ></li>
              <li><input type="text" ></li>
            </ul>
          </div>
        </el-col>
        <el-col :xs="1" :sm="1" :lg="1">
          <div class="grid-content height_font">
            <p>备注</p>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="12">
          <div class="grid-content">
          </div>
        </el-col>
      </el-row>
      <!-- 销方 end -->
    </div>
    
    <div class="bottom_num">
      发票张数
      <el-input style="width:100px" size="small" />
    </div>
</div>
</template>

<script>
export default {
  name:'lkysptfp',
  data() {
    return {
      radio:'1',
      radioStatus:1,
      radioWay:1
    }
  }
}
</script>

<style lang="scss" scoped>
  .top_title {
    width: 100%;
    margin: 0 auto;
    h5 {
      font-size: 30px;
      color: #333;
      text-align: center;
      font-weight: 500;
      margin-bottom: 36px;
    }
  }
  .top_input {
    width: 30%;
    h6 {
      float: left;
      width: 60px;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
    }
  }
  .top_input2 {
    width: 30%;
    margin: 0 auto;
    position: relative;
    h6 {
      float: left;
      width: 60px;
      font-size: 14px;
      color: #333;
      font-weight: normal;
      line-height: 28px;
      margin-right: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .content {
    width: 100%;
    margin: 0 auto;
    border: 1px solid #008000;
    margin-top: 10px;
    border-right: none;
  }
  .grid-content {
    position: relative;
    min-height: 38px;
    font-size: 14px;
    color: #666;
    line-height: 38px;
    text-align: center;
  }
  .row_box2 {
    width: 100%;
    height: 154px;
  }
  .height_font {
    width: 100%;
    height: 152px;
    margin: 0 auto;
    text-align: center;
    p {
      width: 20px;
      margin: 0 auto;
      height: 100%;
      line-height: 28px;
      padding-top: 22px;
    }
  }
  .list_info {
    line-height: 24px;
    &.red li {
      border-bottom: 1px solid #d56400 !important;
    }
    li {
      position: relative;
      line-height: 38px;
      height: 38px;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #008000;

      &:last-child {
        border-bottom: none;
      }
      input {
        width: 100%;
        margin: 0 auto;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #333;
        background: none;
        border: none;
        padding: 0 5px;
      }
    }
  }
  .weight {
    font-weight: 700;
  }
  .weight {
    li {
      font-weight: 600 !important;
    }
  }
  .star {
    color: #f15a24;
    height: 12px;
    font-size: 18px;
    display: inline-block;
    z-index: 999;
  }
  .input_box {
    width: 100%;
    height: 152px;
    font-size: 14px;
    color: #666;
    // line-height: 38px;
    text-align: center;
  }
  .top_font {
    border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 39px;
    font-size: 14px;
    color: #333;
  }
  .item_box {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .close {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      right: -32px;
      top: 9px;
      font-size: 20px;
      color: #ff1818;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .add {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      left: -28px;
      top: 9px;
      font-size: 16px;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    input {
      width: 100%;
      margin: 0 auto;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      color: #333;
      background: none;
      border: none;
      padding: 0 5px;
    }
  }
  .item_list {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    position: relative;
    .add {
      position: absolute;
      right: -38px;
      top: 10px;
      width: 1.8em;
      height: 1.8em;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: #f15a24;
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
    .bg-purple {
      line-height: 38px;
      height: 38px;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid #008000;
      // position: relative;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .bg-purple {
    text-align: center;
  }
  .bottom_num {
    width: 20%;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    font-weight: normal;
    line-height: 36px;
  }
  .total {
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #008000;
    border-bottom: 1px solid #008000;
    line-height: 38px;
    height: 38px;
    font-size: 14px;
    color: #333;
  }
 .el-row {
  width: 100%;
  }
 .el-col {
  border-right: 1px solid #008000;
  }
  .special h5 {
    color: #d56400;
    border-bottom: 4px double #d56400;
    span {
      border-bottom: 16px solid #d56400;
    }
  }
  .special .el-col {
    border-right: 1px solid #d56400;
  }
  .special .content {
    border: 1px solid #d56400;
    border-right: none;
  }
  .special .list_info li {
    border-bottom: 1px solid #d56400;
    &:last-child {
      border-bottom: none;
    }
  }
  .special .textarea {
    border-bottom: 1px solid #d56400;
  }
  .special .top_font {
    border-bottom: 1px solid #d56400;
  }
  .special .item_list {
    border-top: 1px solid #d56400;
  }
  .special .item_box {
    border-top: 1px solid #d56400;
  }
  .special .total {
    border-top: 1px solid #f15a24;
  }
</style>